import React, { useContext, useEffect, useState } from "react";

import {
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentTableActionIcon,
    ContentFilterAction,
    ContentTableAction,
    ContentSpacer
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { exposeStrapiError, fixedTwo } from "utils";
import { VwInvestorsFLBalance, vwAplicacoesIndicadores, vwAplicacoesIndicadoresAdmin } from "services/vw";
import useQuote from "hooks/useQuote";
import { CoreContext } from "context/CoreContext";
import { API_ENDPOINT } from "services/api";
import Button from "components/Form/Button";
import usePageTracking from "hooks/usePageTracking.js";
import { exportData } from "services/xlsx";

export default function DashboardBalance() {
    usePageTracking();

    const { selectedQuote, user } = useContext(CoreContext);

    const { userQuotes } = useQuote();

    const columns = [
        { title: 'Fundo', ref: 'found' },
        { title: 'Vintage', ref: 'vintage' },
        { title: 'Total de compromisso', ref: 'totalcommitment' },
        { title: 'Valor integralizado', ref: 'commitment' },
        { title: 'Amortização aos cotistas', ref: 'shareholders' },
        { title: 'DPI', ref: 'dpi' },
        { title: 'TIR', ref: 'tir' },
    ];

    const columnsInvestor = [
        { title: 'Fundo', ref: 'found' },
        { title: 'Vintage', ref: 'vintage' },
        { title: 'Total de compromisso', ref: 'totalcommitment' },
        { title: 'Total aplicado', ref: 'commitment' },
        { title: 'Total amortizado', ref: 'shareholders' },
        { title: 'DPI', ref: 'dpi' },
    ];

    const [rows, setRows] = useState([]);
    const [rowsInvestor, setRowsInvestor] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCommitment, setTotalCommitment] = useState(0);
    const [totalApplied, setTotalApplied] = useState(0);
    const [totalAmortized, setTotalAmortized] = useState(0);

    const init = async () => {
        setLoading(true);
        const result = await VwInvestorsFLBalance();

        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result.filter(fit => userQuotes?.map(mit => mit.veiculo).includes(fit.veiculo)).map(item => ({
            id: item?.id,
            found: item?.veiculo,
            shareholders: `${fixedTwo(item?.amortizacao_aos_cotistas)}`,
            commitment: `${fixedTwo(item?.compromisso_integralizado)}`,
            totalcommitment: fixedTwo(item?.total_compromisso),
            vintage: item?.vintage,
            dpi: `${fixedTwo(item?.dpi, 2, true)}`,
            tir: `${parseFloat((item?.tir || 0) * 100).toFixed(1)}%`
        })));

        const resultInvestor = user?.spectra && !selectedQuote?.id_origem ? await vwAplicacoesIndicadoresAdmin() : await vwAplicacoesIndicadores(selectedQuote?.id_origem || "");
        const cantSee = !(resultInvestor && !exposeStrapiError(resultInvestor) && resultInvestor.length);

        const groupedResult = resultInvestor.reduce((acc, item) => {
            const vehicle = item.veiculo;
            if (!acc[vehicle]) {
                acc[vehicle] = {
                    found: vehicle,
                    vintage: item.vintage,
                    totalcommitment: 0,
                    commitment: 0,
                    shareholders: 0,
                    dpi: 0
                };
            }
            acc[vehicle].totalcommitment += parseFloat(item?.compromisso || 0);
            acc[vehicle].commitment += parseFloat(item?.total_aplicado || 0);
            acc[vehicle].shareholders += parseFloat(item?.total_amortizado || 0);
            acc[vehicle].dpi += parseFloat(item?.dpi || 0);
            return acc;
        }, {});

        const aggregatedRowsInvestor = Object.values(groupedResult).map(item => ({
            ...item,
            totalcommitment: fixedTwo(item.totalcommitment),
            commitment: fixedTwo(item.commitment),
            shareholders: fixedTwo(item.shareholders),
            dpi: fixedTwo(item.dpi, 2, true)
        }));

        const totalCommitmentSum = resultInvestor.reduce((sum, item) => sum + parseFloat(item?.compromisso || 0), 0);
        const totalAppliedSum = resultInvestor.reduce((sum, item) => sum + parseFloat(item?.total_aplicado || 0), 0);
        const totalAmortizedSum = resultInvestor.reduce((sum, item) => sum + parseFloat(item?.total_amortizado || 0), 0);

        setTotalCommitment(totalCommitmentSum);
        setTotalApplied(totalAppliedSum);
        setTotalAmortized(totalAmortizedSum);

        setRowsInvestor(cantSee ? [] : aggregatedRowsInvestor);
        setLoading(false);
    };

    const exportExcel = async (tableName, fileName, filter, columnMapping) => {
        const omit = ["created_at", "updated_at", "created_by", "updated_by", "id"];
        const blob = await exportData(tableName, omit, filter, columnMapping);

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${fileName}.xlsx`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const sortFund = (a, b) => {
        return a.id - b.id;
    };

    const clearVintage = vintage => {
        return parseInt(`${vintage || ""}`.replace("Vintage", "") || 0);
    };

    const sortVintage = (a, b) => {
        return clearVintage(b.vintage) - clearVintage(a.vintage);
    };

    useEffect(() => {
        if (userQuotes?.length) { init(); }
    }, [userQuotes]);

    const columnMappingInvestidor = {
        veiculo: 'Veículo',
        compromisso_integralizado: 'Compromisso Integralizado',
        amortizacao_aos_cotistas: 'Amortização aos Cotistas',
        total_compromisso: 'Total Compromisso',
        integralizado: 'Integralizado',
        dpi: 'DPI',
        tir: 'TIR',
        vintage: 'Vintage',
        id_origin: 'ID Origin',
        tvpi: 'TVPI',
        pl: 'PL'
    };

    const columnMappingIndicadores = {
        id_cotista: 'ID Cotista',
        id_veiculo: 'ID Veículo',
        compromisso: 'Compromisso',
        total_aplicado: 'Total Aplicado',
        total_amortizado: 'Total Amortizado',
        integralizado: 'Integralizado',
        dpi: 'DPI',
        id_origin: 'ID Origin',
        vintage: 'Vintage',
        veiculo: 'Veículo'
    };

    return (
        <>
            <ContainerAuthenticated page={"investor/balance"}>
                <Row>
                    <Col sm={12}>
                        <ContentFilterAction>
                            <DashboardTitle>
                                Posição Geral Fundos
                            </DashboardTitle>

                            <ContentTableAction>
                                <Button primary nospace small onClick={() => exportExcel('vw-investidor-fl-caixa', 'Posição Geral Fundos', { veiculo: userQuotes.map(m => m.veiculo) }, columnMappingInvestidor)}>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction>

                        <BasicTable columns={columns} rows={rows.sort(sortFund).sort(sortVintage)} loading={loading} />
                    </Col>
                    <Col></Col>
                </Row>
                <ContentSpacer />
                <Row>
                    <Col sm={12}>
                        <ContentFilterAction>
                            <DashboardTitle>
                                Posição Geral Cotista
                            </DashboardTitle>

                            <ContentTableAction>
                                <Button primary nospace small onClick={() => exportExcel('vw-aplicacoes-indicadores', 'Posição Geral Cotista', { veiculo: userQuotes.map(m => m.veiculo) }, columnMappingIndicadores)}>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction>

                        <BasicTable 
                            columns={columnsInvestor} 
                            rows={[...rowsInvestor.sort(sortFund).sort(sortVintage), {
                                found: 'Total',
                                vintage: '',
                                totalcommitment: `R$ ${fixedTwo(totalCommitment)}`,
                                commitment: `R$ ${fixedTwo(totalApplied)}`,
                                shareholders: `R$ ${fixedTwo(totalAmortized)}`,
                                dpi: ''
                            }]} 
                            loading={loading} 
                        />
                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}
