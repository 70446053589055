import { GET, POST, PUT, DELETE } from './api'

export const Create = async (params) => {
    return await POST(`/uploads`, params, true);
}

export const Read = async (page = 1, limit = 100) => {
    const start = (page - 1) * limit; // Calcula o ponto de partida (_start)
    return await GET(`/uploads?_limit=${limit}&_start=${start}`, true);
}


export const ReadUploadByName = async (name) => {
    return await GET(`/uploads?document=${name}`, true);
}

export const ReadOne = async (id) => {
    return await GET(`/uploads/${id}?populate=*`, true);
}

export const Update = async (params, id) => {
    return await PUT(`/uploads/${id}`, params, true);
}

export const Delete = async (id) => {
    return await DELETE(`/uploads/${ id }`, true);
} 
