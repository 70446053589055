import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardTitle,
    ContentInputRow,
    ContentInputField,
    ContentTableAction,
    ContentTableActionIcon
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import Select from "components/Form/Select";
import Button from "components/Form/Button";
import BasicTable from "components/Form/Table";
import { exposeStrapiError, filterNotAllowed, parseStrapiImage } from "utils";
import { Read } from "services/upload";
import useQuote from "hooks/useQuote";
import { CoreContext } from "context/CoreContext";
import Input from "components/Form/Input";

export default function DashboardQuarterReport(){  

    const { setModal } = useContext(CoreContext)
    const { userQuotes } = useQuote()

    const columns = [
        { title:'Período', ref:'document' },
        { 
            ref: 'id', 
            title: 'Visualizar', 
            renderCell: ({ row }) => !row?.file?.url ? null : <>
                <ContentTableAction>
                    <Button primary small nospace link onClick={() => setModal({ type:'pdf', pdf: parseStrapiImage(row.file.url) })}>
                        <ContentTableActionIcon />
                    </Button>
                </ContentTableAction>
            </>  
        },
    ]

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [reportOptions, setReportOptions] = useState([])
    const [filterReportOptions, setFilterReportOptions] = useState(0)

    // Estado de Paginação (infinite scroll)
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true) // Para saber se há mais itens para carregar
    const itemsPerPage = 1000

    const init = async (page = 1) => {
        setLoading(true)
        const uploads = await Read(page, itemsPerPage)

        const result = uploads.filter(fit => !!fit.vwEstrutura)
                              .filter(fit => userQuotes?.map(mit => mit.estrutura)
                              .includes(fit?.vwEstrutura?.name))

        if (uploads.length < itemsPerPage) {
            setHasMore(false) // Se a quantidade retornada for menor que o limite, não há mais itens para carregar
        }

        setRows(prevRows => [
            ...prevRows,
            ...(result && !exposeStrapiError(result) ? result.map(item => ({
                ...item, title: item.document
            })) : [])
        ])

        setReportOptions(prevReportOptions => [
            ...prevReportOptions,
            ...(result && result.length ? result.map(item => ({
                ...item.vwEstrutura, title: item?.vwEstrutura?.name ?? ''
            })).reduce((p,c) => p.map(m=>m.id).includes(c.id) ? p : [...p, c], []) : [])
        ])

        setLoading(false)
    }

    const clearPeriod = document => {
        const clearDoc = `${ document || "" }`.split("T -")?.[0]
        return parseInt( `${ clearDoc || "" }`.split("-")?.[0]+`${ clearDoc || "" }`.split("-")?.[1] )
    }

    const sortPeriod = ( a, b ) => {
        return clearPeriod(b.document) - clearPeriod(a.document)
    }

    const filterReports = fit => {
        return !filterReportOptions || fit?.vwEstrutura?.id === filterReportOptions
    }

    const [searchExpression, setSearchExpression] = useState("")

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item)?.filter(filterNotAllowed)?.filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    // Função para carregar mais itens ao scrollar
    const loadMoreItems = () => {
        if (hasMore && !loading) {
            setCurrentPage(prevPage => prevPage + 1)
        }
    }

    useEffect(() => {
        if (userQuotes?.length) {
            init(currentPage)
        }
    }, [userQuotes, currentPage])

    // Detectar o scroll e carregar mais itens
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && hasMore && !loading) {
                loadMoreItems()
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [hasMore, loading])

    return ( 
        <>
            <ContainerAuthenticated page={"reports/quarter-report"}> 
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>
                            Relatório trimestral
                        </DashboardTitle> 
                        
                        <ContentInputRow>
                            <ContentInputField>
                                <Select placeholder={"Estrutura"} options={reportOptions} value={filterReportOptions} onChange={setFilterReportOptions} />
                            </ContentInputField> 
                            <ContentInputField>
                                <Input placeholder={"Filtrar"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            </ContentInputField> 
                        </ContentInputRow>
                        
                        <BasicTable loading={loading} columns={columns} rows={rows.filter(filterReports).filter(filterExpression).sort(sortPeriod)} />

                        {/* Mensagem de carregamento no final da lista */}
                        {loading && <p>Carregando mais itens...</p>}
                        {!hasMore && <p>Todos os itens foram carregados.</p>}
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}
