import React, { useEffect, useState } from "react";
import { PaginationSpan, DashboardTitle, ContentFilterAction, ContentFilter, ButtonContainer, PaginationContainer } from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select";
import { Read, GetFilters, GetCount } from "services/daycoval-cash";
import Button from "components/Form/Button";

export default function DaycovalCash() {
    const [rows, setRows] = useState([]); // Inicialmente vazio
    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        fundo: [],
    });

    const [filter, setFilter] = useState({
        fundo: '',
        minDate: '',
        maxDate: '',
    });

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100); 
    const [totalPages, setTotalPages] = useState(0); 
    const [filtersLoaded, setFiltersLoaded] = useState(false); 

    const columns = [
        { title: 'Fundo', ref: 'fundo' },
        { title: 'Data', ref: 'data' },
        { title: 'Descrição', ref: 'descricao' },
        { title: 'Entrada', ref: 'entrada' },
        { title: 'Saída', ref: 'saida' },
        { title: 'Saldo', ref: 'saldo' },
    ];

    const fetchUniqueValues = async (column) => {
        try {
            const result = await GetFilters(column);
            return result;
        } catch (error) {
            console.error('Error fetching unique values:', error);
            return [];
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const loadFilters = async () => {
        try {
            const fundoOptions = await fetchUniqueValues('fundo');
            setFilterOptions({
                fundo: fundoOptions,
            });
            setFiltersLoaded(true); 
        } catch (error) {
            console.error('Error loading filters:', error);
        }
    };

    const init = async () => {
        setLoading(true);

        const filters = {
            fundo: filter.fundo,
            data_gte: filter.minDate ? filter.minDate.split('-').join('-') : '',
            data_lte: filter.maxDate ? filter.maxDate.split('-').join('-') : '',
        };

        const totalPagesResult = await GetCount(filters);
        setTotalPages(Math.ceil(totalPagesResult / pageSize));

        const result = await Read(page, pageSize, filters);

        if (result && !result.error) {
            const formattedData = result.map(item => ({
                ...item,
                data: formatDate(item.data),
            }));

            setRows(formattedData);
        } else {
            console.error('Erro ao buscar dados da API:', result.message);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (!filtersLoaded) {
            loadFilters(); // Carrega os filtros inicialmente
        }
    }, [filtersLoaded]); // Não carrega os dados automaticamente, apenas os filtros

    const clearFilters = () => {
        setFilter({
            fundo: '',
            minDate: '',
            maxDate: '',
        });
        setRows([]); // Limpa a tabela ao limpar os filtros
    };

    const handleSearch = () => {
        setPage(1); // Reseta a página para 1 ao buscar
        init(); // Chama a função init para buscar os dados
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
        init(); // Atualiza os dados ao mudar de página
    };

    const escapeCSVValue = (value) => {
        if (value == null) {
            return '';
        }
        const escapedValue = value.toString().replace(/"/g, '""');
        return `"${escapedValue}"`;
    };

    const exportToCSV = async () => {
        let allData = [];
        let currentPage = 1;
        let hasMoreData = true;
    
        while (hasMoreData) {
            const result = await Read(currentPage, pageSize, filter);
    
            if (!result || result.error || result.length === 0) {
                hasMoreData = false;
            } else {
                allData = [...allData, ...result];
                currentPage++;
            }
        }
    
        if (allData.length === 0) {
            console.log("Nenhum dado para exportar.");
            return;
        }
    
        const csvRows = [];
        const headers = columns.map(col => col.title).join(';');
        csvRows.push(headers);
    
        allData.forEach(row => {
            const values = columns.map(col => escapeCSVValue(row[col.ref]));
            csvRows.push(values.join(';'));
        });
    
        const csvString = csvRows.join('\n');
        const blob = new Blob(["\ufeff" + csvString], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'daycoval-cash-report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <>
            <ContainerAuthenticated page={"relatedreport/daycoval-cash"}>
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>Relatório Daycoval</DashboardTitle>

                        <ContentFilterAction>
                            <ContentFilter>
                                <Select
                                    placeholder="Fundo"
                                    options={filterOptions.fundo.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.fundo}
                                    onChange={value => setFilter({ ...filter, fundo: value })}
                                />
                                <input
                                    type="date"
                                    placeholder="Data Mínima"
                                    value={filter.minDate}
                                    onChange={e => setFilter({ ...filter, minDate: e.target.value })}
                                />
                                <input
                                    type="date"
                                    placeholder="Data Máxima"
                                    value={filter.maxDate}
                                    onChange={e => setFilter({ ...filter, maxDate: e.target.value })}
                                />
                            </ContentFilter>
                            <ButtonContainer>
                                <Button primary nospace small onClick={clearFilters}>Limpar</Button>
                                <Button primary nospace small onClick={handleSearch}>Buscar</Button>
                                <Button primary nospace small onClick={exportToCSV}>
                                    Exportar
                                </Button>
                            </ButtonContainer>
                        </ContentFilterAction>

                        <BasicTable loading={loading} columns={columns} rows={rows} />

                        <PaginationContainer>
                            {page > 1 && (
                                <Button
                                    primary nospace small
                                    disabled={page <= 1}
                                    onClick={() => handlePageChange(page - 1)}
                                >
                                    Anterior
                                </Button>
                            )}
                            <PaginationSpan>Página {page} de {totalPages}</PaginationSpan>
                            {rows.length === pageSize && (
                                <Button
                                    primary nospace small
                                    onClick={() => handlePageChange(page + 1)}
                                >
                                    Próxima
                                </Button>
                            )}
                        </PaginationContainer>
                    </Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}
