import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardTitle,
    ContentFilterAction,
    ContentFilter,
    ContentTableAction
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";

import { CoreContext } from "context/CoreContext";

import BasicTable from "components/Form/Table";
import Input from "components/Form/Input";

import { Read } from "services/ent-integralizations";
import { exposeStrapiError, filterNotAllowed } from "utils";

import moment from 'moment';
import 'moment/locale/pt-br';

export default function DashboardRegisterEntryIntegralizations(){  

    const { setModal } = useContext(CoreContext)

    const { searchExpression, setSearchExpression} = useState('')

    const columns = [
        { title:'Cotista', ref:'id_cotista' },
        { title:'Veiculo', ref:'id_veiculo' },
        { title:'Tipo', ref:'tipo' },
        { 
            ref: 'data_mov', 
            title: 'Data mov', 
            renderCell: ({ row }) => <>
                { moment(row.data_mov).format('L') }
            </>  
        },
        { title:'Pu', ref:'pu' },
        { title:'Valor', ref:'valor' },
        { title:'IR', ref:'ir' },
        { title:'IOF', ref:'iof' }
    ] 


    const [rows, setRows] = useState([ ])
    const [loading, setLoading] = useState(false)

    const init = async () => { 
        setLoading(true)
        const result = await Read()
        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            ...item
        }))) 
        setLoading(false)
    }


    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item)?.filter(filterNotAllowed)?.filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }
        
    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={"register/entry-integralizations"}> 
                <Row>
                    {/* <Col sm={12} md={10} xl={9}> */}
                    <Col sm={12}>
                        <DashboardTitle>
                            Ent integralizações
                        </DashboardTitle> 


                        <ContentFilterAction>
                            <ContentFilter>
                                <Input placeholder={"Buscar por"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            </ContentFilter>
                            <ContentTableAction>
                                {/* <Button primary nospace small onClick={() => setModal({ type:'quote' })}>
                                    Novo Cotista
                                    <ContentTableActionIcon />
                                </Button> */}
                            </ContentTableAction>
                        </ContentFilterAction>


                        {/* 
                            <DashboardText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut congue nisl. Duis lobortis ante vitae hendrerit efficitur. Quisque dolor magna, efficitur et nunc id, pretium porta mauris. Integer vel lobortis risus. Duis consectetur ac ante at ullamcorper. Nullam luctus sollicitudin odio, eu gravida lorem efficitur ac. Nam pulvinar consequat cursus. Maecenas convallis sagittis erat, at tristique lectus pharetra et. In eget metus nisl. Maecenas orci ipsum, sagittis non erat eleifend, dignissim suscipit mi. Ut a tellus quis enim pellentesque fringilla. Sed in malesuada felis, eget volutpat neque.
                            </DashboardText>  
                        */}

                        <BasicTable loading={loading} columns={columns} rows={rows?.filter(filterExpression)} />

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}