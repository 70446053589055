import {
    DashboardTitle
} from "./styled.js";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import FormReset from "../FormReset/index.js";
import FormUpdateData from "../FormUpdateData/index.js";

export default function MyData(){  

    return ( 
        <>
            <ContainerAuthenticated page={"users/reset"}> 
                <Row>
                    <Col sm={12} md={8}>
                        <DashboardTitle>
                            Alterar meus dados
                        </DashboardTitle> 

                        <FormUpdateData />

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}