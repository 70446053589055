import React from "react";
import { Chart } from "react-google-charts";

export const data = [
  [
    "Trimestre",
    "Amortizado",
    "Aplicação",
    "Acumulado",
  ],
  ["1TRI-2018", 1650, -1650, 614.6],
  ["2TRI-2018", 1350, -1350,  682],
  ["3TRI-2018", 1570, -1570,  623],
  ["4TRI-2018", 1390, -1390,  609.4],
  ["1TRI-2019", 1650, -1650, 614.6],
  ["2TRI-2019", 1350, -1350,  682],
  ["3TRI-2019", 1570, -1570,  623],
  ["4TRI-2019", 1390, -1390,  609.4],
  ["1TRI-2020", 1650, -1650, 614.6],
  ["2TRI-2020", 1350, -1350,  682],
  ["3TRI-2020", 1570, -1570,  623],
  ["4TRI-2020", 1390, -1390,  609.4],
  ["1TRI-2021", 1650, -1650, 614.6],
  ["2TRI-2021", 1350, -1350,  682],
  ["3TRI-2021", 1570, -1570,  623],
  ["4TRI-2021", 1390, -1390,  609.4],
  ["1TRI-2022", 1650, -1650, 614.6],
  ["2TRI-2022", 1350, -1350,  682],
  ["3TRI-2022", 1570, -1570,  623],
  ["4TRI-2022", 1390, -1390,  609.4],
];

export const options = {
  title: " ",
  vAxis: { title: " " },
  hAxis: { title: " " },
  seriesType: "bars",
  series: { 2: { type: "line" } },
  colors:[
    '#2E5496',
    '#B4C7E7',
    '#FFC314'
  ]
};

export function ComboChart({ infos }) {
  return (
    <Chart
      chartType="ComboChart"
      width="100%"
      height="400px"
      data={infos ?? data}
      options={options}
    />
  );
}
