import React, { useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import { Read } from "services/returnRates";
import { exposeStrapiError } from "utils";

export default function DashboardPaybackRate(){  

    const columns = [
        { title:'Estratégia', ref:'strategy' }, 
        { title:'Empresa', ref:'company' }, 
        { title:'Gestor líder', ref:'leader' }, 
        { title:'Modo de investimento', ref:'mode' }, 
        { title:'Setor', ref:'sector' }, 
        { title:'Data de entrada', ref:'income_date' }, 
        { title:'Valor investido', ref:'value_invest' }, 
        { title:'Valor desinvestido', ref:'value_withdraw' }
    ]
    
    // const rows = [
    //     { strategy:'Mid-Market Buyout', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:'Mid-Market Buyout', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:'Mid-Market Buyout', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:' ', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:' ', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:' ', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' },
    //     { strategy:' ', company:'ABC da Contrução', leader:'Danica', mode:'CO-Investimento', sector:'Aluguel', income_date:'Ago/22', value_invest:'1,1%', value_withdraw:'1,1%' }
    // ]

    const columns1 = [
        { title:'Valor remanescente', ref:'value' }, 
        { title:'MOIC', ref:'moic' }, 
        { title:'TIR', ref:'tir' }, 
        { title:'Status', ref:'status' }, 
    ]
    
    // const rows1 = [
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    //     { value:'1,1%', moic:'1,1%', tir:'1,1%', status:'Bom' },
    // ]
    
    const [rows, setRows] = useState([ ])
    const [rows1, setRows1] = useState([ ])
    const [loading, setLoading] = useState(false)

    const init = async () => {
        setLoading(true)
        const result = await Read()
        // console.log(result)
        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            strategy:item?.strategy, company:item?.company, leader:item.manager, mode: item.investmentMode, sector: item.sector, income_date: item.entryDate, value_invest:`${ item.investedAmount }%`, value_withdraw:`${ item.withdrawnAmount }%`
        })))
        setRows1(!(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            value:`${ item.remainingValue }%`, moic:`${ item.moic }%`, tir:`${ item.tir }%`, status: item?.status ? 'Bom' : 'Neutro'
        })))
        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])

    return ( 
        <>
            <ContainerAuthenticated page={"investor/payback-rate"}> 
                <Row>
                    {/* <Col sm={12} md={9}> */}
                    <Col sm={12}>
                        <DashboardTitle>
                            Taxa de retorno
                        </DashboardTitle> 
                        {/* 
                            <DashboardText>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut congue nisl. Duis lobortis ante vitae hendrerit efficitur. Quisque dolor magna, efficitur et nunc id, pretium porta mauris. Integer vel lobortis risus. Duis consectetur ac ante at ullamcorper. Nullam luctus sollicitudin odio, eu gravida lorem efficitur ac. Nam pulvinar consequat cursus. Maecenas convallis sagittis erat, at tristique lectus pharetra et. In eget metus nisl. Maecenas orci ipsum, sagittis non erat eleifend, dignissim suscipit mi. Ut a tellus quis enim pellentesque fringilla. Sed in malesuada felis, eget volutpat neque.
                            </DashboardText>  
                        */}

                        <DashboardText>Informações do Portfólio</DashboardText>  
                        <DashboardText>&nbsp;</DashboardText>  
                        <BasicTable loading={loading} columns={columns} rows={rows} />

                    </Col>
                    <Col sm={12} md={3}>
                        <DashboardTitle>&nbsp;</DashboardTitle>

                        <DashboardText>Reportado pelo gestor</DashboardText>  
                        <DashboardText>&nbsp;</DashboardText>  
                        <BasicTable loading={loading} columns={columns1} rows={rows1} />
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}