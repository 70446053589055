import React, { useState } from "react"; 
import PropTypes from 'prop-types'; 

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import TextField from '@mui/material/TextField';

import { 
    InputLabelContent,
    MaterialAutocomplete
} from "./styled";
import { ThemedComponent } from "ui/theme";

export const Autocomplete = ({ placeholder, options, small, value, onChange, id, secondary, label, outline, color }) => {  

    const [opened, setOpened] = useState(false)

    const toggleOpen = () => {
        setOpened(!opened)
    }

    const optionAction = value => {
        if(onChange && typeof onChange === 'function'){
            onChange(value)
        }
        toggleOpen()
    } 

    return (
        <>
            <ThemedComponent>
                {/* { label ? <InputLabelContent outline={outline}>{ label }</InputLabelContent> : null } */}
                {/* <FormControl variant={ outline ? "outlined" : "standard" } 
                             color={ secondary ? 'secondary' : color ? color : 'primary' }  
                             fullWidth > */}
                        {/* <InputLabel id={id} color={ secondary ? 'secondary' :  color ? color : 'primary' } >{ placeholder }</InputLabel> */}

                        <MaterialAutocomplete
                            disablePortal
                            id={`select-${id}`}
                            labelId={id} 
                            
                            color={ secondary ? 'secondary' : color ? color : 'primary' } 

                            getOptionLabel={(option) => option?.title || options?.find( fnd => fnd?.id === option )?.title || ""}
                            options={ options } 
                            renderInput={(params) => <TextField {...params} label={placeholder} color={ secondary ? 'secondary' : color ? color : 'primary' } variant={ outline ? "outlined" : "standard" }  />}
                            // value={options?.find( fnd => fnd?.id === value )}
                            // onChange={optionAction}

                            value={value?.id || 0}
                            onChange={(e, newValue) => { optionAction(newValue) }}

                            // label={placeholder}
                        />



                        {/* <MaterialSelect
                            small={small}
                            labelId={id} 
                            color={ secondary ? 'secondary' : color ? color : 'primary' } 
                            id={`select-${id}`}
                            value={value}
                            onChange={optionAction}
                            label={placeholder}
                        > 
                            {
                                options?.map((item, key) => 
                                    <MenuItem key={key} value={item.id} 
                                    color={ secondary ? 'secondary' : color ? color : 'primary' }  >{ item?.title }</MenuItem> 
                                )
                            } 
                        </MaterialSelect> */}
                {/* </FormControl>   */}
            </ThemedComponent>
        </>
    )
}


Autocomplete.propTypes = {  
    placeholder: PropTypes.string, 
    options: PropTypes.array, 
    value: PropTypes.number, 
    secondary: PropTypes.bool, 
    onChange: PropTypes.func.isRequired,
};


Autocomplete.defaultProps = {
    placeholder: '',
    options: [],
    value: undefined, 
    small: false, 
    secondary: false, 
    onChange: undefined,
};

export default Autocomplete; 
