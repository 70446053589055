
import React, { useContext, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import { 
    ModalBody, 
    ModalHeader,
    ModalHeaderClose, 
} from './styled'


import ModalWrapper from 'components/Modal/ModalWrapper'
import PptViewer from 'components/Dashboard/PptViewer'

// Core viewer
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

export default function ModalPpt(){

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const { modal, setModal } = useContext(CoreContext)
    
    const [step, setStep] = useState(0)
 
    const [success, setSuccess] = useState(false)

    const steps = [
        { title: "Dados iniciais" },
        { title: "Documentos" },
        { title: "Confirmação" }
    ]

    const reportOptions = [
        { id:1, title:'Spectra I' },
        { id:2, title:'Spectra II' },
        { id:3, title:'Spectra III' },
        { id:4, title:'Spectra IV' }
    ]

    const close = () => {
        setModal(null)
    } 

    const complete = () => { 
        setSuccess(true)
    }
    
    const next = () => {
        if( step+1 < steps.length ){
            setStep(step+1)
        }else{
            complete()
        }
    }

    const back = () => {
        if( step-1 >= 0 ){
            setStep(step-1)
        }else{
            close()
        }
    }

    // console.log("modal?.pdf", modal?.pdf)

    return (
        <>
            <ModalWrapper big>
                <ModalHeader success={success}>
                    &nbsp;
                    <ModalHeaderClose success={success} onClick={close}>
                        x
                    </ModalHeaderClose>
                </ModalHeader>
                
                        <ModalBody> 
                            <PptViewer document={modal?.ppt} />
                        </ModalBody> 

            </ModalWrapper>
        </>
    )
}