import { useCallback } from 'react'
import PropTypes from 'prop-types';

import {      
  UploadFileContent
} from './styled'  

import {useDropzone} from 'react-dropzone'
import { exposeStrapiError } from 'utils'
import { UploadImage } from 'services/api'

const UploadFile = ({ onChange, onPreview, accept = 'image/*', children, validate, onUploadStart, many }) => {  

  const onDrop = useCallback(acceptedFiles => {
    if(many){
      loadImageMany(acceptedFiles) 
    } else {
      loadImage(acceptedFiles) 
    }
  }, [])

  const loadImage = async files => {
    const [file] = files
    if (file) { 
      if( onUploadStart && typeof onUploadStart === 'function' ){ onUploadStart() ;}
      if( validate && typeof validate === 'function' ){ if(! await validate(file)){ return ;} }
      if( onPreview && typeof onPreview === 'function' ){ onPreview(URL.createObjectURL(file)) ;}
      upFile(file)
    }
  }

  const loadImageMany = async files => { 
    if( onUploadStart && typeof onUploadStart === 'function' ){ onUploadStart() ;}
    const promises = files?.map(UploadImage)
    const results = await Promise.all(promises) 
    if( results.filter(result => !exposeStrapiError(result) )?.length === results?.length ) { 
      if( onChange && typeof onChange === 'function' && results?.length ){ onChange(results) ;} 
    } 
  }
  
  const upFile = async file => {
    const result = await UploadImage(file)
    if(!exposeStrapiError(result)){
      if( onChange && typeof onChange === 'function' && result?.length ){ onChange(result?.[0]) ;}
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, maxFiles: many ? 100 : 1, accept }) 

  return (
    <>   
      <UploadFileContent {...getRootProps()}>
        <input {...getInputProps()} style={{display:'none'}} />
        { children }
      </UploadFileContent> 
    </>
  );
}


UploadFile.propTypes = { 
  onChange: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  accept: PropTypes.string,
  children: PropTypes.element.isRequired
};


UploadFile.defaultProps = {
  onChange: undefined,
  onPreview: undefined,
  accept: 'image/*',  
  children: undefined
};

export default UploadFile; 