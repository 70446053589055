import ContainerTrackQuotesRelease from "containers/TrackQuotesRelease/index.js";
import { Col, Row } from "reactstrap";
import { ContentFilterAction, DashboardTitle } from "../Fix/styled.js";
import BasicTable from "components/Form/Table/index.js";
import { Read } from "services/quote-releases.js";
import { useEffect, useState } from "react";

export default function DashboardTrackQuotesRelease() {

  const columns = [
    { title: 'CNPJ', ref: 'cnpj' },
    { title: 'Data Cota', ref: 'dataCota' },
    { title: 'Cota', ref: 'cota' },
    { title: 'Status', ref: 'status' },
    { title: 'Previsão de Liberação', ref: 'releaseForecast' },
  ];

  const [rows, setRows] = useState([]);

  const init = async () => {
    const result = await Read();

    // console.log('result', result)

    const processedResults = result.map(release => ({
      cnpj: release?.cnpj,
      dataCota: release?.dataCota,
      cota: release?.cota,
      status: release?.status,
      releaseForecast: release?.releaseForecast,
    }));

    // console.log('processedResults', processedResults.length)

    setRows(processedResults);
  };





  useEffect(() => {
    init()
  }, [])

  return (
    <ContainerTrackQuotesRelease page={"register/actives"}>
      <Row>
        <Col sm={12}>
          <ContentFilterAction>
            <DashboardTitle>
              Liberação de cotas
            </DashboardTitle>

          </ContentFilterAction>

          <BasicTable columns={columns} rows={rows} />
        </Col>
        <Col></Col>
      </Row>
    </ContainerTrackQuotesRelease>
  )
}