import React from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentFilterAction,
    ContentTableAction,
    ContentTableActionIcon
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Button from "components/Form/Button";

export default function DashboardRelatedReportContabil(){  


    const columns = [
        { title:'Fundo', ref:'found' },
        { title:'Amortização aos cotistas', ref:'shareholders' },
        { title:'Compromisso integralizado', ref:'commitment' },
        { title:'Total de compromisso', ref:'totalcommitment' },
        { title:'Caixas', ref:'cashiers' },
        { title:'DPI', ref:'dpi' },
    ]
    
    const rows = [
        { found:'Black', shareholders:'188.307.865', commitment:'170.174.000', totalcommitment:'176.020.618', cashiers:'2.552.603', dpi:'1,10x' },
        { found:'Spectra I', shareholders:'188.307.865', commitment:'170.174.000', totalcommitment:'176.020.618', cashiers:'2.552.603', dpi:'1,10x' },
        { found:'Spectra II', shareholders:'188.307.865', commitment:'170.174.000', totalcommitment:'176.020.618', cashiers:'2.552.603', dpi:'1,10x' },
        { found:'Spectra III', shareholders:'188.307.865', commitment:'170.174.000', totalcommitment:'176.020.618', cashiers:'2.552.603', dpi:'1,10x' },
        { found:'Spectra IV', shareholders:'188.307.865', commitment:'170.174.000', totalcommitment:'176.020.618', cashiers:'2.552.603', dpi:'1,10x' },
        { found:'Total', shareholders:'1.199.477.261.21', commitment:'2.172.710.199,25', totalcommitment:'3.342.417.983,81', cashiers:'61.819.605,83', dpi:'0,55x' },
    ]

    return ( 
        <>
            <ContainerAuthenticated page={"relatedreport/contabil"}> 
                <Row>
                    <Col sm={12} >
                        <DashboardTitle>
                            Caixa contábil
                        </DashboardTitle> 

                        <ContentFilterAction> 
                            <ContentTableAction>
                                <Button primary nospace small onClick={() => null }>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction>


                        {/* 
                            <DashboardText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut congue nisl. Duis lobortis ante vitae hendrerit efficitur. Quisque dolor magna, efficitur et nunc id, pretium porta mauris. Integer vel lobortis risus. Duis consectetur ac ante at ullamcorper. Nullam luctus sollicitudin odio, eu gravida lorem efficitur ac. Nam pulvinar consequat cursus. Maecenas convallis sagittis erat, at tristique lectus pharetra et. In eget metus nisl. Maecenas orci ipsum, sagittis non erat eleifend, dignissim suscipit mi. Ut a tellus quis enim pellentesque fringilla. Sed in malesuada felis, eget volutpat neque.
                            </DashboardText>  
                        */}

                        <BasicTable columns={columns} rows={rows} />


                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}