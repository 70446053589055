import { GET } from './api';

export const Read = async (page = 1, pageSize = 1000, filters = {}) => {
    try {
        const start = (page - 1) * pageSize;

        // Adiciona os filtros na URL, removendo os parâmetros vazios
        const queryParams = new URLSearchParams({
            _start: start,
            _limit: pageSize,
        });

        // Adiciona filtros específicos, incluindo operadores
        if (filters.assetname) {
            queryParams.append('assetname', filters.assetname);
        }
        if (filters.minDate) {
            queryParams.append('operationdate_gte', filters.minDate);
        }
        if (filters.maxDate) {
            queryParams.append('operationdate_lte', filters.maxDate);
        }

        const url = `/api-btg-caixa?${queryParams.toString()}`;
        const response = await GET(url, true, true);
        return response;
    } catch (error) {
        console.error('Erro ao ler dados da API BTG Caixa:', error);
        return { error: true, message: 'Erro ao ler dados da API BTG Caixa' };
    }
};


export const GetFilters = async (column) => {
    const url = `/api-btg-caixa/unique-values/${column}`;
    const response = await GET(url, true, true);
    return response;
};


export const GetCount = async (filters = {}) => {
    // Monta a query string com os filtros passados
    const queryParams = new URLSearchParams().toString();

    if (filters.assetname) {
        queryParams.append('assetname', filters.assetname);
    }
    if (filters.minDate) {
        queryParams.append('operationdate_gte', filters.minDate);
    }
    if (filters.maxDate) {
        queryParams.append('operationdate_lte', filters.maxDate);
    }

    console.log("Aqui:", filters)
    
    // Ajuste a URL para chamar o endpoint de contagem com filtros
    const url = `/api-btg-caixa/count-with-filters?${queryParams}`;
    
    // Realiza a requisição GET passando a URL com os filtros
    const response = await GET(url, true, true);
    
    return response;
};
