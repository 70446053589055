import React, { useState, useEffect } from 'react';
import { Button, Avatar, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { Container, Input } from './styled.js';

export const AvatarUpload = ({ onFileSelect, currentAvatar }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(currentAvatar);

  useEffect(() => {
    setAvatarUrl(currentAvatar);
  }, [currentAvatar]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setSelectedFile(fileUrl);
      setAvatarUrl(fileUrl);
      onFileSelect(file);
    }
  };

  return (
    <Container>
      <Avatar
        alt="User Avatar"
        src={avatarUrl}
        sx={{ width: 100, height: 100 }}
      />
      <label htmlFor="upload-button">
        <Input
          accept="image/*"
          id="upload-button"
          type="file"
          onChange={handleFileChange}
        />
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      {selectedFile && (
        <Button variant="contained" color="primary" onClick={() => setSelectedFile(null)}>
          Remover
        </Button>
      )}
    </Container>
  );
};
