import React, { useEffect, useState } from "react";
import { DashboardTitle, ContentFilterAction, ContentFilter, ButtonContainer } from "./styled";
import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select";
import { Read, GetFilters } from "services/btg-cash";
import Button from "components/Form/Button";

export default function BTGCashReportApplications() {
    const [rows, setRows] = useState([]); // Começa vazio
    const [loading, setLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState({
        assetname: [],
    });

    const [filter, setFilter] = useState({
        assetname: '',
        minDate: '',
        maxDate: '',
    });

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(100); 

    const columns = [
        { title: 'Fundo', ref: 'assetname' },
        { title: 'Data', ref: 'operationdate' },
        { title: 'Histórico', ref: 'history' },
        { title: 'Observações', ref: 'observation' },
        { title: 'Remetente', ref: 'remet' },
        { title: 'Crédito', ref: 'credit' },
        { title: 'Débito', ref: 'debt' },
        { title: 'Balanço', ref: 'balance' },
    ];

    const fetchUniqueValues = async (column) => {
        try {
            const result = await GetFilters(column);
            return result;
        } catch (error) {
            console.error('Error fetching unique values:', error);
            return [];
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const loadFilters = async () => {
        try {
            const assetnameOptions = await fetchUniqueValues('assetname');
            setFilterOptions({
                assetname: assetnameOptions,
            });
        } catch (error) {
            console.error('Error loading filters:', error);
        }
    };

    const loadData = async (page) => {
        const filters = {
            assetname: filter.assetname,
            operationdate_gte: filter.minDate ? filter.minDate.split('-').join('-') : '',
            operationdate_lte: filter.maxDate ? filter.maxDate.split('-').join('-') : '',
        };

        try {
            const result = await Read(page, pageSize, filters);
            if (result && !result.error && result.length > 0) {
                const formattedData = result.map(item => ({
                    ...item,
                    operationdate: formatDate(item.operationdate),
                }));
                setRows(formattedData); // Define os dados retornados
            } else {
                setRows([]); // Limpa os dados se não houver resultados
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const applyFilters = () => {
        setLoading(true); // Começa o carregamento
        setCurrentPage(1); // Reseta a página atual
        loadData(1); // Carrega os dados com os filtros aplicados
        setLoading(false); // Finaliza o carregamento (pode ser feito após a resposta)
    };

    const clearFilters = () => {
        setFilter({
            assetname: '',
            minDate: '',
            maxDate: '',
        });
        setRows([]); // Limpa os dados ao limpar os filtros
        setCurrentPage(1); // Reseta a página atual
    };

    const escapeCSVValue = (value) => {
        if (value == null) {
            return '';
        }
        const escapedValue = value.toString().replace(/"/g, '""');
        return `"${escapedValue}"`; // Adiciona aspas ao valor
    };

    const exportToCSV = async () => {
        const allData = [];
        let currentPage = 1;
        let hasMoreData = true;

        // Continuar carregando páginas até que não haja mais dados
        while (hasMoreData) {
            const result = await Read(currentPage, pageSize, filter);

            if (!result || result.error || result.length === 0) {
                hasMoreData = false;
            } else {
                allData.push(...result);
                currentPage++;
            }
        }

        if (allData.length === 0) {
            console.log("Nenhum dado para exportar.");
            return;
        }

        // Formatar dados em CSV
        const csvRows = [];
        const headers = columns.map(col => col.title).join(';');
        csvRows.push(headers);

        allData.forEach(row => {
            const values = columns.map(col => escapeCSVValue(row[col.ref]));
            csvRows.push(values.join(';'));
        });

        const csvString = csvRows.join('\n');
        const blob = new Blob(["\ufeff" + csvString], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'btg-cash-report.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    useEffect(() => {
        loadFilters(); // Carrega as opções de filtro na montagem do componente
    }, []);

    return (
        <>
            <ContainerAuthenticated page={"relatedreport/btg-cash"}>
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>BTG Caixa</DashboardTitle>

                        <ContentFilterAction>
                            <ContentFilter>
                                <Select
                                    placeholder="Fundo"
                                    options={filterOptions.assetname.map(opt => ({ id: opt, title: opt }))}
                                    value={filter.assetname}
                                    onChange={value => setFilter({ ...filter, assetname: value })}
                                />
                                <input
                                    type="date"
                                    placeholder="Data Mínima"
                                    value={filter.minDate}
                                    onChange={e => setFilter({ ...filter, minDate: e.target.value })}
                                />
                                <input
                                    type="date"
                                    placeholder="Data Máxima"
                                    value={filter.maxDate}
                                    onChange={e => setFilter({ ...filter, maxDate: e.target.value })}
                                />
                            </ContentFilter>
                            <ButtonContainer>
                                <Button primary nospace small onClick={clearFilters}>Limpar</Button>
                                <Button primary nospace small onClick={applyFilters}>
                                    Buscar
                                </Button>
                                <Button primary nospace small onClick={exportToCSV}>
                                    Exportar
                                </Button>
                            </ButtonContainer>
                        </ContentFilterAction>

                        <BasicTable loading={loading} columns={columns} rows={rows} />
                    </Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}
