import React, { useContext, useState } from 'react'
import { CoreContext } from 'context/CoreContext'

import {
    FormTitle,
    FormText, 

    UpIcon,
    ModalBody,
    LoadContent,
    Load,
    FormActions,
    ModalHeader,
    ModalHeaderClose,
    ContentUpload,
    ContentUploadIcon,
    ContentUploadText,
    ContentUploadIconStatus,
    ContentTableActionIconFileSuccess,
    FormContentActions,
    FormRow,
    FormRowInput,
    ContentSteps,
    StepItem,
    StepItemText,
    StepItemDecoration
} from './styled'

import Button from 'components/Form/Button'
import ModalWrapper from 'components/Modal/ModalWrapper'
import { ButtonIcon } from 'ui/styled'
import Select from 'components/Form/Select'
import Input from 'components/Form/Input'
import Radio from 'components/Form/Radio'
import Check from 'components/Form/Check'

export default function ModalQuote(){

    const { modal, setModal } = useContext(CoreContext)
    
    const [step, setStep] = useState(0)
 
    const [success, setSuccess] = useState(false)

    const steps = [
        { title: "Dados iniciais" },
        { title: "Documentos" },
        { title: "Confirmação" }
    ]

    const reportOptions = [
        { id:1, title:'Spectra I' },
        { id:2, title:'Spectra II' },
        { id:3, title:'Spectra III' },
        { id:4, title:'Spectra IV' }
    ]

    const close = () => {
        setModal(null)
    } 

    const complete = () => { 
        setSuccess(true)
    }
    
    const next = () => {
        if( step+1 < steps.length ){
            setStep(step+1)
        }else{
            complete()
        }
    }

    const back = () => {
        if( step-1 >= 0 ){
            setStep(step-1)
        }else{
            close()
        }
    }

    return (
        <>
            <ModalWrapper>
                <ModalHeader success={success}>
                    Novo cotista 
                    <ModalHeaderClose success={success} onClick={close}>
                        x
                    </ModalHeaderClose>
                </ModalHeader>
                {
                    success ? 
                        <ModalBody> 
                            <ContentTableActionIconFileSuccess /> 
                            <FormTitle>
                                Dados coletados
                            </FormTitle>
                            <FormTitle>
                                Acompanhe o processo do cadastro através do status. 
                            </FormTitle>
                            <FormContentActions>
                                <Button primary onClick={close}> Ok </Button>
                            </FormContentActions>
                        </ModalBody>
                     :  
                        <ModalBody>

                            <ContentSteps>
                                {
                                    steps.map((item, key) => 
                                        <StepItem key={key}>
                                            <StepItemText active={step < key }>{ item.title }</StepItemText>
                                            <StepItemDecoration active={step < key}>
                                                { key + 1 }
                                            </StepItemDecoration>
                                        </StepItem>
                                    )
                                }
                            </ContentSteps>

                            {
                                step === 0 ? <>
                                    <FormRowInput>
                                        <Input placeholder={"Nome completo"} label={"Nome completo"} />
                                    </FormRowInput>
                                    <FormRowInput>
                                        <Input placeholder={"Ex: mail@mail.com"} label={"Email"} />
                                    </FormRowInput>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Fundo"} label={"Fundo"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Select outline label={"Perfil"} placeholder={"Perfil"} options={reportOptions} />
                                        </FormRowInput>
                                    </FormRow>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Comprimisso"} label={"Comprimisso"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Classificação"} label={"Classificação"} />
                                        </FormRowInput>
                                    </FormRow> 
                                    <FormText>Investidor estrangeiro?</FormText>
                                    <FormRow>
                                        <Radio label={"Sim"} /> 
                                        <Radio label={"Não"} /> 
                                    </FormRow>
                                </> : null
                            }
                            
                            {
                                step === 1 ? <>
                                    <FormRowInput>
                                        <Input placeholder={"CPF/CNPJ"} label={"CPF/CNPJ"} />
                                    </FormRowInput>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Banco"} label={"Banco"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Número da conta"} label={"Número da conta"} />
                                        </FormRowInput>
                                    </FormRow>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Subscrição"} label={"Subscrição"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Aporte"} label={"Aporte"} />
                                        </FormRowInput>
                                    </FormRow>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Comprimisso"} label={"Comprimisso"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Taxa de ingresso"} label={"Taxa de ingresso"} />
                                        </FormRowInput>
                                    </FormRow>
                                    <FormRowInput>
                                        <Input placeholder={"Email contato"} label={"Ex: mail@mail.com"} />
                                    </FormRowInput>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Data de assinatura"} label={"Data de assinatura"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Bados Amortização"} label={"Bados Amortização"} />
                                        </FormRowInput>
                                    </FormRow>  
                                </> : null
                            }
                            
                            {
                                step === 2 ? <>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"RG"} label={"RG"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"CPF"} label={"CPF"} />
                                        </FormRowInput> 
                                    </FormRow>
                                    <FormRowInput>
                                        <Input placeholder={"Cartão CNPJ"} label={"Cartão CNPJ"} />
                                    </FormRowInput>
                                    <FormRow>
                                        <FormRowInput>
                                            <Input placeholder={"Ficha de cadastro"} label={"Ficha de cadastro"} />
                                        </FormRowInput>
                                        <FormRowInput>
                                            <Input placeholder={"Comprimisso"} label={"Comprimisso"} />
                                        </FormRowInput>
                                    </FormRow>
                                    <FormRowInput>
                                        <Input placeholder={"Boletim de subscrição"} label={"Boletim de subscrição"} />
                                    </FormRowInput> 
                                    <FormRowInput>
                                        <Check label={"Termo de adesão"} /> 
                                    </FormRowInput> 
                                </> : null
                            }

                            <FormActions>
                                <Button secondary small onClick={back}>Cancelar</Button>
                                <Button small primary onClick={next}>Continuar</Button>
                            </FormActions>
                        </ModalBody>
                }
            </ModalWrapper>
        </>
    )
}