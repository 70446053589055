import { createTheme } from '@mui/material/styles'; 

export const theme = createTheme({
  mode: 'light',
  palette: {
    darkPrimary: {
      main: '#101636', 
      contrastText: '#fff',
    },
    primary: {
      main: '#012E5E', 
      contrastText: '#fff',
    },
    secondary: {
      main: '#C62828',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#ffffff',
    },
    error: {
      main: '#dd4952',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#66bb6a',
    },
    colors:{
        white: '#ffffff',
        background: '#F5F5F5',
        black: '#000000',
        grey: '#404040',
        blue: '#012E5E',
        yellow: '#F5BB07',
        backgroundgrey: '#F7F7F7',
        lightgrey: '#A4A4A4',
        shadow: 'rgba(0,0,0,.16)',
        blackopacity: 'rgba(0,0,0,.9)',
        lightshadow: 'rgba(112,112,112,.06)',
        modalshadow: 'rgba(0,0,0,.4)',
    }
  },
});  