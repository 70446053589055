import styled from 'styled-components'  
 
import ReactLoading from 'react-loading';


export const UpIcon = styled.img.attrs({ 
    width: 56,
    src:'/icons/upload.svg'
})`
    margin: 20px auto 40px;
`;


export const ModalHeader = styled.div.attrs({ 
})`           
    background: ${ props => props.theme.palette.primary.main };
    padding: 20px;
    // margin: -20px -32px 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: ${ props => props.theme.palette.colors.white };
    font-weight: bolder;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${
        props => props.success ? `
            background: ${ props.theme.palette.colors.white };
        ` : ``
    }
`;

export const ModalHeaderClose = styled.div.attrs({ 
})`           
    border: 1px solid ${ props => props.theme.palette.colors.white };
    color: ${ props => props.theme.palette.colors.white };
    border-radius: 12px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 1px 0px;
    &:hover{
        background: ${ props => props.theme.palette.colors.white };
        color: ${ props => props.theme.palette.colors.blue };
    }

    ${
        props => props.success ? `
            border: 1px solid ${ props.theme.palette.secondary.main };
            color: ${ props.theme.palette.secondary.main };
            &:hover{
                background: ${ props.theme.palette.secondary.main };
                color: ${ props.theme.palette.colors.white };
            }
        ` : ``
    }

`;

export const ModalBody = styled.div.attrs({ 
})`           
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
`;

export const ModalBodyInput = styled.div.attrs({ 
})`           
    flex:1;
    margin: -8px 12px 8px;
`;

export const LoadContent = styled.div.attrs({ 
})`           
    max-width: 56px;
    margin: 20px auto 40px;
`;

export const ContentUploadContent = styled.div.attrs({ 
})`
    flex: 1;
`;

export const Load = styled(ReactLoading).attrs(props => ({ 
    type:'spinningBubbles',
    color:  props.theme.palette.primary.main,
    height:56,
    width:56
}))`           
`;
  

export const FormTitle = styled.div.attrs({ 
})`           
    color: ${ props => props.theme.palette.colors.black }; 
    font-weight: bold;
    font-size: 20px; 


    max-width: 240px;

    margin: 0px auto 20px;
    text-align:center;
`;
export const FormText = styled.div.attrs({ 
})`           
    font-size: 15px; 
    color: ${ props => props.theme.palette.colors.grey };
 
    margin-bottom: 10px;
    text-align:center;
`;

export const FormActions = styled.div.attrs({ 
})`           
    display: flex;
    gap: 20px;
    width: 100%s;
    width: 300px;
    margin: 20px auto 0;
`;






export const FormSpacer = styled.div.attrs({ 
})`           
    margin-top: 20px;
`; 



export const ContentUpload = styled.div.attrs({ 
})`
    margin-top: 16px;
    padding: 12px 16px;
    border: 1px solid ${ props => props.theme.palette.colors.shadow };
    color:  ${ props => props.theme.palette.colors.grey };
    cursor: pointer;
    display: flex;
    align-items: center;
    flex:1;

`;

export const ContentUploadIcon = styled.img.attrs({ 
    src: '/icons/file-pdf.svg',
})`           
    margin-right: 10px;
`;

export const ContentUploadText = styled.div.attrs({ 
})`
    flex:1;
    overflow: hidden;
    margin-right: 10px;
`;

export const FormContentActions = styled.div.attrs({ 
})`
    width: 100%;
    max-width: 160px;
    margin: 0px auto;
`;

export const ContentUploadIconStatus = styled.img.attrs( props => ({ 
    src: props.uploaded ? '/icons/file-checked.svg' : '/icons/upload-circle.svg'
}))`           
    margin-right: 10px;
`;


export const ContentTableActionIconFileSuccess = styled.img.attrs({ 
    src: '/icons/file-success.svg',
    width: 52
})`           
    margin: 32px auto;
`;



 