import React, { useContext, useEffect, useState } from "react";

import {
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentFilter,
    ContentTableAction,
    ContentFilterAction,
    ContentTableActionIcon,
    ContentInputRow,
    ContentInputField
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Button from "components/Form/Button";
import Select from "components/Form/Select";
import { exposeStrapiError, filterNotAllowed, fixedTwo } from "utils";

import { VwReportsQuotas } from "services/vw";
import { Read as ReadV } from "services/cad-vehicles";

import moment from 'moment';
import 'moment/locale/pt-br';
import { DownloadCSV } from "services/core";
import { API_ENDPOINT } from "services/api";
import useQuote from "hooks/useQuote";
import { CoreContext } from "context/CoreContext";
import Input from "components/Form/Input";
import { exportData } from "services/xlsx";

export default function DashboardQuotesEvolutions() {

    // const reportOptions = [
    //     { id:1, title:'Spectra I' },
    //     { id:2, title:'Spectra II' },
    //     { id:3, title:'Spectra III' },
    //     { id:4, title:'Spectra IV' }
    // ]

    const { selectedQuote } = useContext(CoreContext)
    const { userQuotes } = useQuote()

    const columns = [
        { title: 'Data', ref: 'date' },
        { title: 'Patrimonio', ref: 'patrimony' },
        { title: 'Valor da cota', ref: 'quotes_value' },
        { title: 'Quantidade de cotas', ref: 'quotes_quantity' },
    ]

    // const rows = [
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    //     { date: '20/12/2022', patrimony:'2.597.175.03', quotes_value:'170.174.000', quotes_quantity:'3.634.166.65' },
    // ]

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [reportOptions, setReportOptions] = useState([])
    const [filterReportOptions, setFilterReportOptions] = useState(0)


    const [vehicles, setVehicles] = useState([])
    const [filterVehicles, setFilterVehicles] = useState(null)
    const [fullResult, setFullResult] = useState(null)

    const init = async () => {
        setLoading(true)
        // const result = await Read()
        const result_qt = await VwReportsQuotas()

        const result = result_qt?.filter(fit => userQuotes?.map(mit => mit?.id_veiculo).includes(fit?.veiculo))
        // console.log('VwReportsQuotas', result, result_qt, userQuotes)

        const resultVehicles_qt = await ReadV()
        const resultVehicles = resultVehicles_qt?.filter(fit => userQuotes?.map(mit => mit?.id_veiculo).includes(fit?.id_origem))
        setVehicles(resultVehicles)

        setFullResult({
            result,
            resultVehicles
        })

    }

    const printView = () => {
        setLoading(true)

        const origin = vehicles?.find(fnd => fnd.id === filterVehicles)?.id_origem ?? null
        const result = origin ? fullResult?.result?.filter(fit => fit?.veiculo === origin) : fullResult?.result


        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result?.sort((a, b) => new Date(a.data_movimentacao).getTime() - new Date(b.data_movimentacao).getTime())?.map(item => ({
            date: moment(item.data_movimentacao).format('L'), patrimony: fixedTwo(item.patrimonio_liq), quotes_value: fixedTwo(item.cota_liq, 6), quotes_quantity: fixedTwo(item.qtd_cotas, 6)
        })))
        setReportOptions(!(result && result.length) ? [] : result.map(item => ({
            ...item.fund, title: item?.fund?.name ?? ''
        })).reduce((p, c) => p.map(m => m.id).includes(c.id) ? p : [...p, c], []))
        setLoading(false)
    }

    const filterReports = fit => {
        return !filterReportOptions || fit?.fund?.id === filterReportOptions
    }

    const exportExcel = async () => {
        const fund = vehicles?.map(item => ({ ...item, title: item.veiculo })).find(fnd => fnd?.id === filterVehicles);

        const omit = ["created_at", "updated_at", "created_by", "updated_by", "id", "id_origin", "fundo"];
        const filter = { veiculo: fund?.id_origem };
        const columnMapping = {
            veiculo: 'Veículo',
            data_movimentacao: 'Data Movimentação',
            patrimonio_liq: 'Patrimônio Líquido',
            cota_liq: 'Cota Líquida',
            qtd_cotas: 'Quantidade de Cotas'
        };

        const blob = await exportData('vw-reports-cotas', omit, filter, columnMapping);

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = 'Evolução de cotas.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    const clearDate = date => {
        return parseInt(`${date || ""}`.split("/")?.[2] + `${date || ""}`.split("/")?.[1] + `${date || ""}`.split("/")?.[0])
    }

    const sortDate = (a, b) => {
        return clearDate(b.date) - clearDate(a.date)
    }

    useEffect(() => {
        if (userQuotes?.length) { init(); }
    }, [userQuotes])

    useEffect(() => {
        if (fullResult?.result && filterVehicles) {
            printView()
        }
    }, [fullResult, filterVehicles])

    const [searchExpression, setSearchExpression] = useState("")

    const filterExpression = item => {
        return (!searchExpression || Object.keys(item)?.filter(filterNotAllowed)?.filter(k => `${item[k]}`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1).length > 0)
    }

    return (
        <>
            <ContainerAuthenticated page={"reports/quotes-evolutions"}>
                <Row>
                    {/* <Col sm={12} md={9} xl={8}> */}
                    <Col sm={12}>
                        <DashboardTitle>
                            Evolução de cotas
                        </DashboardTitle>

                        <ContentInputRow>
                            <ContentInputField>
                                <Select placeholder={"Fundos (Veículos)"} options={vehicles?.map(item => ({ ...item, title: item.veiculo }))} value={filterVehicles} onChange={setFilterVehicles} />
                            </ContentInputField>
                            {
                                !filterVehicles ? null : <>
                                    <ContentInputField>
                                        <ContentTableAction>
                                            <Button primary nospace small onClick={exportExcel}>

                                                Exportar
                                                <ContentTableActionIcon />
                                            </Button>
                                        </ContentTableAction>
                                        <Input placeholder={"Filtrar"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                                    </ContentInputField>
                                </>
                            }
                        </ContentInputRow>

                        {/* <ContentFilterAction>
                            <ContentFilter>
                                <Select placeholder={"Fundo"} options={reportOptions} value={filterReportOptions} onChange={filterReportOptions} />
                            </ContentFilter>
                            <ContentTableAction>
                                <Button primary nospace small onClick={() => null}>
                                    Exportar
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction> */}


                        {/* 
                            <DashboardText>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ut congue nisl. Duis lobortis ante vitae hendrerit efficitur. Quisque dolor magna, efficitur et nunc id, pretium porta mauris. Integer vel lobortis risus. Duis consectetur ac ante at ullamcorper. Nullam luctus sollicitudin odio, eu gravida lorem efficitur ac. Nam pulvinar consequat cursus. Maecenas convallis sagittis erat, at tristique lectus pharetra et. In eget metus nisl. Maecenas orci ipsum, sagittis non erat eleifend, dignissim suscipit mi. Ut a tellus quis enim pellentesque fringilla. Sed in malesuada felis, eget volutpat neque.
                            </DashboardText>  
                        */}

                        {filterVehicles && <BasicTable loading={loading} columns={columns} rows={rows.filter(filterReports).filter(filterExpression).sort(sortDate)} />}

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated>
        </>
    );
}