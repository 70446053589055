import styledCmp from 'styled-components'  

import { styled } from '@mui/material/styles'; 

// import Input from '@mui/material/Input'; // standard (material)
// import Input from '@mui/material/FilledInput'; 
import Input from '@mui/material/OutlinedInput'; 


export const MaterialInput = styled(Input)(({ theme }) => ({  
}));

export const InputIcon = styledCmp.img.attrs({ 
})`
`;

export const InputLabelContent = styledCmp.div.attrs({ 
})`
    font-size: 16px;
    font-weight: bolder;
    color: ${ props => props.theme.palette.colors.blackopacity }; 
    padding-bottom: 8px;
`;