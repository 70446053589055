const { default: styled } = require("styled-components");

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const Input = styled.input`
  display: none;
`;