import styled from 'styled-components'  

import {
    Animation
} from 'ui/styled'

export const DashboardTitle = styled.div.attrs({ 
})`            
    font-size: 22px;
    font-weight: bold;
    color: ${ props => props.theme.palette.colors.black };
    margin-bottom: 12px;
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardText = styled.div.attrs({ 
})`            
    font-size: 16px;
    line-height: 26px;
    color: ${ props => props.theme.palette.colors.black };
    ${
        props => props.centred ? `
            text-align: center;
        ` : ``
    }
`;

export const DashboardAnimation = styled(Animation).attrs({ 
    width: '100%',
    height: 420
})`             
`;




export const ContentFilter = styled.div.attrs({ 
})`            
    width: 100%;
    max-width: 320px;
`;

export const ContentFilterAction = styled.div.attrs({ 
})`            
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const ContentTableAction = styled.div.attrs({ 
})`            
    max-width: 180px;
    min-width: 160px; 
    display: flex;
    margin-left: auto;
`;

export const ContentTableActionIcon = styled.img.attrs({ 
    src: '/icons/circle-add.svg'
})`          
    margin-left: 10px;   
`;

export const ContentTableActionIconRemove = styled.img.attrs({ 
    src: '/icons/remove.svg',
    width: 12
})`           
`;

export const ContentTableActionIconEdit = styled.img.attrs({ 
    src: '/icons/edit.svg',
    width: 12
})`           
`;

export const ContentTableActionIconFile = styled.img.attrs({ 
    src: '/icons/file-pdf.svg',
    width: 12
})`           
    margin-right: 10px;
`;
