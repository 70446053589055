import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardAnimation,
    DashboardTitle,
    DashboardText,
    ContentFilterAction,
    ContentFilter,
    ContentTableAction,
    ContentTableActionIcon,
    ContentTableActionIconEdit,
    ContentTableActionIconRemove,
    ContentTableActionIconFile
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Select from "components/Form/Select";
import Button from "components/Form/Button";
import { CoreContext } from "context/CoreContext";
import Input from "components/Form/Input";
import { exposeStrapiError, filterNotAllowed } from "utils";
import { Delete, Read } from "services/users";

export default function DashboardUsersHome(){  

    const { setModal, modal } = useContext(CoreContext)

    const [searchExpression, setSearchExpression] = useState('')

    const columns = [
        { title:'Usuário', ref:'name' },
        { title:'E-mail', ref:'email' },
        // { title:'Fundo', ref:'found' },
        { title:'Perfil', ref:'profile' },
        { title:'Status', ref:'status' },
        { 
            title: 'Ação', 
            renderCell: ({ row }) => <>
                <ContentTableAction>
                    <Button primary nospace small link onClick={() => remove(row)}>
                        <ContentTableActionIconRemove />
                    </Button>
                    <Button primary nospace small link onClick={() => edit(row)}>
                        <ContentTableActionIconEdit />
                    </Button>
                </ContentTableAction>
            </>  
        },
    ]
    
    // const rows = [
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' },
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
    //     { name:'Lorem Ipsum.pdf', email:'angelo.santos@icloud.com', found:'Spectra I', profile:'Cotista', status:'Documentação liberada', upload_at: '20/12/2022' }, 
    // ]

    
    const [rows, setRows] = useState([ ])
    const [loading, setLoading] = useState(false)

    const init = async () => { 
        setLoading(true)
        const result = await Read()
        setRows(!(result && !exposeStrapiError(result) && result.length) ? [] : result.map(item => ({
            ...item, profile: item.spectra ? 'Spectra' : 'Cotista', status: item.confirmed? 'Documentação liberada' : 'Em analise', upload_at: item.created_at
        }))) 
        setLoading(false)
    }

    const remove = async item => {
        const result = await Delete(item.id)
        if(result && !exposeStrapiError(result)){
            init()
        }
    }
    
    const edit = async item => {
        setModal({ type:'user', user:item })        
    }
    
    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item)?.filter(filterNotAllowed)?.filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }
        

    useEffect(() => {
        init()
    }, [modal])

    return ( 
        <>
            <ContainerAuthenticated page={"users/home"}> 
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>
                            Usuários
                        </DashboardTitle> 


                        <ContentFilterAction>
                            <ContentFilter>
                                <Input placeholder={"Buscar por"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            </ContentFilter>
                            <ContentTableAction>
                                <Button primary nospace small onClick={() => setModal({ type:'user' })}>
                                    Novo Usuário
                                    <ContentTableActionIcon />
                                </Button>
                            </ContentTableAction>
                        </ContentFilterAction>

                        <BasicTable loading={loading} columns={columns} rows={rows.filter(filterExpression)} />

                    </Col>
                    <Col></Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}