import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Landpage from 'screens/Landpage'
import NotFound from 'screens/NotFound'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Forgot from 'screens/Authentication/Forgot'
import CreatePassword from 'screens/Authentication/CreatePassword'

import DashboardBalance from "screens/Dashboard/Investors/Balance";
import DashboardApplications from "screens/Dashboard/Investors/Applications";
import DashboardIndicators from "screens/Dashboard/Investors/Indicators";
import DashboardPaybackRate from "screens/Dashboard/Investors/PaybackRate";

import DashboardQuarterReport from "screens/Dashboard/Reports/QuarterReport";
import DashboardQuotesEvolutions from "screens/Dashboard/Reports/QuotesEvolutions";
import DashboardUploads from "screens/Dashboard/Reports/Uploads";
import DashboardActiveAndFounds from "screens/Dashboard/Reports/ActiveAndFounds";
import DashboardMonthlyWallet from "screens/Dashboard/Reports/MonthlyWallet";

import DashboardRegisterQuotes from "screens/Dashboard/Registers/Quotes";
import DashboardRegisterActives from "screens/Dashboard/Registers/Actives";
import DashboardRegisterFounds from "screens/Dashboard/Registers/Founds";

import DashboardRelatedReportOperational from "screens/Dashboard/RelatedReport/Operational";
import DashboardRelatedReportContabil from "screens/Dashboard/RelatedReport/Contabil";
import DashboardRelatedReportCapital from "screens/Dashboard/RelatedReport/Capital";
import DashboardRelatedReportApplications from "screens/Dashboard/RelatedReport/Applications";
import BTGCashReportApplications from "screens/Dashboard/RelatedReport/BTGCash";

import DashboardUsersHome from "screens/Dashboard/Users/Home";
import DashboardRegisterVehicles from "screens/Dashboard/Registers/Vehicles";
import DashboardRegisterEntryCommitmentPassives from "screens/Dashboard/Registers/EntryCommitmentPassives";
import DashboardRegisterEntryQuotes from "screens/Dashboard/Registers/EntryQuotes";
import DashboardRegisterEntryIntegralizations from "screens/Dashboard/Registers/EntryIntegralizations";
import ResetPassword from "screens/Dashboard/Users/ResetPassword";
import DashboardTrackQuotesRelease from "screens/Dashboard/TrackQuotesRelease/index.js";
import MyData from "screens/Dashboard/Users/MyData/index.js";
import DaycovalCash from "screens/Dashboard/RelatedReport/DaycovalCash";

export default function AppRouter() {
  return (
    <Router>
      <div>
        <Switch>
          {/* <Route path="/" exact> <Landpage /> </Route>  */}

          <Route path="/" exact> <Login /> </Route>
          <Route path="/login" exact> <Login /> </Route>
          {/* <Route path="/register" exact> <Register /> </Route>  */}
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/create-password" exact> <CreatePassword /> </Route>

          <Route path="/dashboard" exact> <DashboardBalance /> </Route>

          <Route path="/dashboard/investor/applications" exact> <DashboardApplications /> </Route>
          <Route path="/dashboard/investor/balance" exact> <DashboardBalance /> </Route>
          <Route path="/dashboard/investor/indicators" exact> <DashboardIndicators /> </Route>
          <Route path="/dashboard/investor/payback-rate" exact> <DashboardPaybackRate /> </Route>

          <Route path="/dashboard/reports/quarter-report" exact> <DashboardQuarterReport /> </Route>
          <Route path="/dashboard/reports/monthly-wallet" exact> <DashboardMonthlyWallet /> </Route>
          <Route path="/dashboard/reports/actives-and-founds" exact> <DashboardActiveAndFounds /> </Route>
          <Route path="/dashboard/reports/quotes-evolutions" exact> <DashboardQuotesEvolutions /> </Route>

          <Route path="/dashboard/register/quotes" exact> <DashboardRegisterQuotes /> </Route>
          <Route path="/dashboard/register/actives" exact> <DashboardRegisterActives /> </Route>
          <Route path="/dashboard/register/founds" exact> <DashboardRegisterFounds /> </Route>

          <Route path="/dashboard/register/uploads" exact> <DashboardUploads /> </Route>
          <Route path="/dashboard/register/vehicles" exact> <DashboardRegisterVehicles /> </Route>
          <Route path="/dashboard/register/entry-commitment-passives" exact> <DashboardRegisterEntryCommitmentPassives /> </Route>
          <Route path="/dashboard/register/entry-quotes" exact> <DashboardRegisterEntryQuotes /> </Route>
          <Route path="/dashboard/register/entry-integralizations" exact> <DashboardRegisterEntryIntegralizations /> </Route>

          <Route path="/dashboard/relatedreport/operational" exact> <DashboardRelatedReportOperational /> </Route>
          <Route path="/dashboard/relatedreport/contabil" exact> <DashboardRelatedReportContabil /> </Route>
          <Route path="/dashboard/relatedreport/capital" exact> <DashboardRelatedReportCapital /> </Route>
          <Route path="/dashboard/relatedreport/applications" exact> <DashboardRelatedReportApplications /> </Route>
          <Route path="/dashboard/relatedreport/btg-cash" exact> <BTGCashReportApplications /> </Route>
          <Route path="/dashboard/relatedreport/daycoval-cash" exact> <DaycovalCash /> </Route>


          <Route path="/dashboard/users/home" exact> <DashboardUsersHome /> </Route>

          <Route path="/dashboard/reset/password" exact> <ResetPassword /> </Route>
          <Route path="/dashboard/config/my-data" exact> <MyData /> </Route>
          <Route path="/cotas" exact> <DashboardTrackQuotesRelease /> </Route>

          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
      </div>
    </Router>
  );
}