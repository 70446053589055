import styled from 'styled-components';

export const DashboardTitle = styled.div.attrs({
})`
    font-size: 22px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.black};
    margin-bottom: 12px;
    ${props => props.centred ? `text-align: center;` : ``}
`;

export const ContentFilter = styled.div.attrs({
})`
    display: flex;
    gap: 10px;
    flex-wrap: nowrap;
    width: 100%;
`;

export const ContentFilterAction = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
`;

export const ContentTableAction = styled.div.attrs({
})`
    max-width: 180px;
    min-width: 160px;
    display: flex;
    margin-left: auto;
`;

export const ClearButton = styled.button`
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #0056b3;
    }
`;


export const ContentTableActionIcon = styled.img.attrs({ 
    src: '/icons/upload.svg',
    width: 12
})`          
    margin-left: 10px;   
`;

export const ButtonContainer = styled.div.attrs({})`
    display: flex;
    gap: 10px;
    max-width: 250px;
    margin-left: 10px;
`;


export const PaginationContainer = styled.div.attrs({})`
    display: flex;
    gap: 30px;
    padding: 0 30%;
    align-items: center;
`;

export const PaginationSpan = styled.span.attrs({})`
    display: flex;
    flex-direction: column;
    align-items: center;
`;