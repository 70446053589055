import React, { useContext, useState } from "react";
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';


import {
    FormTitle,
    FormText,
    FormSpacer,
    RegisterForgot,
    ForgotLink,
    RegisterCall,
    FormButtonLimiter
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { DoLogin } from "services/authentication";
import { exposeStrapiError } from "utils";
import Hotjar from "@hotjar/browser";

export default function Login() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const [loading, setLoading] = useState(false)
    const { setUser } = useContext(CoreContext)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const valid = (verbose = false) => {

        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) { toast.error('Preencha o campo: Email'); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error('Preencha o campo: Senha'); }
            return false;
        }

        return true
    }

    const login = async () => {
        if (!valid(true)) { return; }
        setLoading(true)
        const result = await DoLogin({ ...form, identifier: form.identifier?.replace(/ /g, '') })
        setLoading(false)
        if (result && !exposeStrapiError(result)) {
            completeLogin(result)
        }
    }

    const completeLogin = (result) => {
        if(result?.user){ 
            setUser(result.user);
            
            const now = new Date();
            
            localStorage.setItem('loginTimestamp', now.getTime().toString());
    
            const userId = result.user.id.toString();
            const userUsername = result.user.username;
            const userEmail = result.user.email;
            
            Hotjar.identify(userId, {
              username: userUsername,
              email: userEmail,
              login_timestamp: now.toISOString().replace('T', ' ').substring(0, 19)
            });
            
            navigate('dashboard');
        }  
    };
    
    

    return (
        <>
            <ContainerUnauthenticated>
                <FormTitle>Bem vindo!</FormTitle>
                {/* <FormText>Entre com seus dados do cadastro para acessar sua conta</FormText>  */}
                <Input label="E-mail" value={formValue('identifier')} onChange={e => changeForm(e.target.value, 'identifier')} />
                <FormSpacer />
                <Input label="Senha" type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} />
                <RegisterForgot>
                    <ForgotLink onClick={() => navigate('forgot')}>Esqueceu sua senha?</ForgotLink>
                    <FormButtonLimiter>
                        <Button primary nospace loading={loading} onClick={login}>Entrar</Button>
                    </FormButtonLimiter>
                </RegisterForgot>
                <FormSpacer />
                {/* <RegisterCall> Ainda não tem conta? </RegisterCall> */}
                <Button primary outline onClick={() => navigate('cotas')}>Acompanhar liberação de cotas</Button> 
            </ContainerUnauthenticated>
        </>
    );
}