import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Hotjar from '@hotjar/browser';
import { CoreContext } from 'context/CoreContext.js';

export default function usePageTracking() {
  const location = useLocation();
  const { user } = useContext(CoreContext);

  useEffect(() => {
    const pagePath = location.pathname + location.search;
    Hotjar.stateChange(pagePath);


    if (user && user.id && user.email) {
      Hotjar.identify(user.id.toString(), { email: user.email });
    }
  }, [location, user]);

  const sendEventToHotjar = (eventName) => {
    Hotjar.event(eventName);
  };

  return { sendEventToHotjar };
}
