import { GET } from './api';

export const Read = async (page = 1, pageSize = 1000, filters = {}) => {
    try {
        const start = (page - 1) * pageSize;

        // Adiciona os filtros na URL, removendo os parâmetros vazios
        const queryParams = new URLSearchParams({
            _start: start,
            _limit: pageSize,
        });

        // Adiciona filtros específicos, incluindo operadores
        if (filters.descHistorico) {
            queryParams.append('fundo', filters.descHistorico);
        }
        if (filters.conta) {
            queryParams.append('descricao', filters.conta);
        }
        if (filters.minDate) {
            queryParams.append('data_gte', filters.minDate);
        }
        if (filters.maxDate) {
            queryParams.append('data_lte', filters.maxDate);
        }

        const url = `/api-daycoval-caixa?${queryParams.toString()}`;
        const response = await GET(url, true, true);
        return response;
    } catch (error) {
        console.error('Erro ao ler dados da API BTG Caixa:', error);
        return { error: true, message: 'Erro ao ler dados da API BTG Caixa' };
    }
};


export const GetFilters = async (column) => {
    const url = `/api-daycoval-caixa/unique-values/${column}`;
    const response = await GET(url, true, true);
    return response;
};


export const GetCount = async () => {
    const url = `/api-daycoval-caixa/count`;
    const response = await GET(url, true, true);
    return response;
};