import React, { useContext } from 'react'
import { CoreContext } from 'context/CoreContext'

import ModalUpload from "./ModalUpload" 
import ModalNotification from './ModalNotification'
import ModalQuote from './ModalQuote'
import ModalUser from './ModalUser'
import ModalPdf from './ModalPdf'
import ModalPpt from './ModalPpt'

export default function ModalCore(){
    const { modal } = useContext(CoreContext)
    return (
        <>
            { modal?.type === 'upload' ? <ModalUpload /> : null }
            { modal?.type === 'notification' ? <ModalNotification /> : null }
            { modal?.type === 'quote' ? <ModalQuote /> : null }
            { modal?.type === 'user' ? <ModalUser /> : null }
            { modal?.type === 'pdf' ? <ModalPdf /> : null }
            { modal?.type === "ppt" ? <ModalPpt /> : null }
        </>
    )
}