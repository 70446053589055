import { GET, POST, POST_FORM_DATA } from "./api.js";

export const Read = async () => {
  return await GET(`/quote-releases`);
}

export const CreateRelease = async (formData) => {
  return await POST_FORM_DATA(`/quote-releases`, formData, true); 
}


