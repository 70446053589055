import React, { useContext, useEffect, useState } from "react";  

import {  
    DashboardTitle,
    ContentTableAction,
    ContentTableActionIcon,
    ContentTableActionIconExpand,
    ContentTableActionRow
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import { Row, Col } from "reactstrap";
import BasicTable from "components/Form/Table";
import Button from "components/Form/Button";
import Select from "components/Form/Select";
import { exposeStrapiError, filterNotAllowed, parseStrapiImage } from "utils";
import { Read } from "services/upload";
import useQuote from "hooks/useQuote";
import { CoreContext } from "context/CoreContext";
import { ContentInputField, ContentInputRow } from "screens/Dashboard/Investors/Balance/styled";
import Input from "components/Form/Input";

export default function DashboardMonthlyWallet(){  
    
    const { selectedQuote, setModal } = useContext(CoreContext)
    const { userQuotes } = useQuote()

    const columns = [
        { title:'Período', ref:'document' },
        { 
            title: 'Download / Visualizar', 
            renderCell: ({ row }) => !row?.file?.url ? null : <ContentTableActionRow>
                <ContentTableAction>
                    <Button primary small nospace link onClick={() => window.open(parseStrapiImage(row.file.url), '')}>
                        <ContentTableActionIcon />
                    </Button>
                </ContentTableAction>
                <ContentTableAction>
                    <Button primary small nospace link onClick={() => setModal({ type:'pdf', pdf: parseStrapiImage(row.file.url) })}>
                        <ContentTableActionIconExpand />
                    </Button>
                </ContentTableAction>
            </ContentTableActionRow>  
        },
    ]

    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(false)
    const [reportOptions, setReportOptions] = useState([])
    const [filterReportOptions, setFilterReportOptions] = useState(0)

    // Estado para controle da paginação por scroll
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const itemsPerPage = 1000

    const init = async (page = 1) => {
        setLoading(true)
        const uploads = await Read(page, itemsPerPage)

        const result = uploads.filter(fit => !!fit.cadVeiculo)
                              .filter(fit => userQuotes?.map(mit => mit.id_veiculo)
                              .includes(fit?.cadVeiculo?.id_origem))

        if (uploads.length < itemsPerPage) {
            setHasMore(false)
        }

        setRows(prevRows => [
            ...prevRows,
            ...(result && !exposeStrapiError(result) ? result.map(item => ({
                ...item
            })) : [])
        ])

        setReportOptions(prevReportOptions => [
            ...prevReportOptions,
            ...(result && result.length ? result.map(item => ({
                ...item.cadVeiculo, title: item?.cadVeiculo?.veiculo ?? ''
            })).reduce((p,c) => p.map(m=>m.id).includes(c.id) ? p : [...p, c], []) : [])
        ])

        setLoading(false)
    }

    const filterReports = fit => {
        return !filterReportOptions || fit?.cadVeiculo?.id === filterReportOptions
    }

    const [searchExpression, setSearchExpression] = useState("")

    const filterExpression = item => {
        return ( !searchExpression || Object.keys(item)?.filter(filterNotAllowed)?.filter(k => `${ item[k] }`.toLowerCase().indexOf(searchExpression.toLowerCase()) !== -1 ).length > 0)
    }

    // Função para carregar mais itens ao scrollar
    const loadMoreItems = () => {
        if (hasMore && !loading) {
            setCurrentPage(prevPage => prevPage + 1)
        }
    }

    useEffect(() => {
        if(userQuotes?.length){ init(currentPage) }
    }, [userQuotes, currentPage])

    // Detectar o scroll e carregar mais itens
    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && hasMore && !loading) {
                loadMoreItems()
            }
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [hasMore, loading])

    return ( 
        <>
            <ContainerAuthenticated page={"reports/monthly-wallet"}> 
                <Row>
                    <Col sm={12}>
                        <DashboardTitle>
                            Carteira mensal
                        </DashboardTitle> 

                        <ContentInputRow>
                            <ContentInputField>
                                <Select placeholder={"Fundo"} options={reportOptions} value={filterReportOptions} onChange={setFilterReportOptions} />
                            </ContentInputField>
                            <ContentInputField>
                                <Input placeholder={"Filtrar"} value={searchExpression} onChange={e => setSearchExpression(e.target.value)} />
                            </ContentInputField>
                        </ContentInputRow>
                        
                        <BasicTable loading={loading} columns={columns} rows={rows.filter(filterReports).filter(filterExpression)} />

                        {/* Mensagem de carregamento no final da lista */}
                        {loading && <p>Carregando mais itens...</p>}
                        {!hasMore && <p>Todos os itens foram carregados.</p>}
                    </Col>
                </Row>
            </ContainerAuthenticated> 
        </>
    );
}
