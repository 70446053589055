import React, { useContext, useEffect, useState } from 'react'
import { CoreContext } from 'context/CoreContext'
import { toast } from 'react-toastify';

import {
    FormTitle,
    FormText,

    UpIcon,
    ModalBody,
    LoadContent,
    Load,
    FormActions,
    ModalHeader,
    ModalHeaderClose,
    ContentUpload,
    ContentUploadIcon,
    ContentUploadText,
    ContentUploadIconStatus,
    ContentTableActionIconFileSuccess,
    FormContentActions,
    FormSpacer,
    ContentUploadContent,
    ModalBodyInput
} from './styled'

import Button from 'components/Form/Button'
import ModalWrapper from 'components/Modal/ModalWrapper'
import { ButtonIcon } from 'ui/styled'
import Select from 'components/Form/Select'
import { Input } from '@mui/material'
import UploadFile from 'components/Form/UploadFile'
import { Create, Update } from 'services/upload'
import { exposeStrapiError } from 'utils';
import { Read } from 'services/cad-vehicles';
import { Read as ReadS } from 'services/structure';
import { CreateRelease } from 'services/quote-releases.js';

export default function ModalUpload() {

    const { modal, setModal } = useContext(CoreContext)

    const [loading, setLoading] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [uploaded, setUploaded] = useState(false)
    const [success, setSuccess] = useState(false)

    const [filenames, setFilenames] = useState([])
    const [filefunds, setFilefunds] = useState([])


    const [selectedType, setSelectedType] = useState(null)
    const [fund, setFund] = useState(null)
    const [document, setDocument] = useState("")
    const [file, setFile] = useState(null)
    const [editMode, setEditMode] = useState(false);



    const [structure, setStructure] = useState([])
    const [fileStructures, setFileStructures] = useState([]);
    const [structureOptions, setStructureOptions] = useState([])
    const [reportOptions, setReportOptions] = useState([
        // { id:1, title:'Spectra I' },
        // { id:2, title:'Spectra II' },
        // { id:3, title:'Spectra III' },
        // { id:4, title:'Spectra IV' }
    ])

    const reportType = [
        { id: 1, title: 'Relatório Trimestral' },
        { id: 2, title: 'Carteira mensal' },
        { id: 3, title: 'Liberação de cotas' },
    ]

    const close = () => {
        setModal(null)
    }

    const action = (uploaded) => {
        // console.log('uploaded', uploaded)

        setFile(uploaded)
        setUploaded({ filename: uploaded?.name })
        
        if (Array.isArray(uploaded)) {
            setFileStructures(uploaded.map(() => null));
            setUploading(false);
    
            if (modal?.many) {
                setFilenames(uploaded.map(i => getFilename(i[0])));
                setFilefunds(uploaded.map(i => fund));
                console.log("fund", fund, uploaded.map(i => getFilename(i[0])), uploaded.map(i => fund));
            }
        } else {
            setFileStructures([null]);
            setUploading(false);
        }


        if (modal?.many) {
            setFilenames(uploaded.map(i => getFilename(i[0])))
            setFilefunds(uploaded.map(i => fund))
            console.log("fund", fund, uploaded.map(i => getFilename(i[0])), uploaded.map(i => fund))

            return
        }

        // setUploading(true)
        // setTimeout(() => {
        //     if(modal?.action?.action && typeof modal?.action?.action === 'function'){
        //         modal?.action?.action()
        //     }
        //     setUploaded({
        //         filename: 'Lorem Ipsum.pdf'
        //     })
        // }, 4000)
    }

    const complete = async () => {
        if (selectedType === 1) {
            if (!structure) {
                toast.error('Selecione a Estrutura');
                return;
            }
        }
        if (selectedType === 2) {
            if (!(fund || (filefunds?.filter(f => f)?.length === file?.length))) {
                toast.error('Selecione o Fundo');
                return;
            }
        } else if (selectedType === 3) {
            if (!file || !file.id) {
                toast.error('Escolha o Arquivo');
                return;
            }
    
            setLoading(true);
    
            const response = await fetch(file.url);
            if (!response.ok) {
                toast.error('Erro ao carregar o arquivo');
                setLoading(false);
                return;
            }
            const fileBlob = await response.blob();
    
            const formData = new FormData();
            formData.append('file', fileBlob, file.name);
    
            const result = await CreateRelease(formData, true);
    
            if (result && !result.error) {
                setSuccess(true);
                if (modal?.action && typeof modal?.action === 'function') {
                    modal.action();
                }
            } else {
                toast.error('Erro ao fazer upload do arquivo: ' + result.message);
            }
    
            setLoading(false);
            return; // Sair da função, pois já tratamos o selectedType 3
        }
        
        if (!modal.many) {
            if (!file || !file.id) {
                toast.error('Escolha o Arquivo');
                return;
            }
    
            if (!document) {
                toast.error('Informe o título do documento');
                return;
            }
        } else {
            if (!file || !file.length) {
                toast.error('Escolha os Arquivos');
                return;
            }
        }
    
        setLoading(true);
        if (modal?.many) {
            const promises = file.map(m => m[0]).map((ff, kk) => registerUpload(getFilename(ff), ff, kk));
            const results = await Promise.all(promises);
            if (results.filter(result => result && !exposeStrapiError(result)).length === promises.length) {
                setSuccess(true);
                if (modal?.action && typeof modal?.action === 'function') {
                    modal.action();
                }
            }
        } else {
            const result = await registerUpload(document, file);
            if (result && !exposeStrapiError(result)) {
                setSuccess(true);
                if (modal?.action && typeof modal?.action === 'function') {
                    modal.action();
                }
            }
        }
        setLoading(false);
    };
    
    
    

    const getFilename = it => {
        const snippets = `${it?.name || ""}`.split(".")
        return snippets.slice(0, snippets?.length - 1).join('-') || it.name
    }

    const registerUpload = async (document, file, key) => {
        
        const payload = selectedType === 2 ? {
            cadVeiculo: (modal?.many ? filefunds?.[key] : fund),
            document: (modal?.many ? filenames[key] : document), 
            file: file?.id
        } : {
            vwEstrutura: structure,
            vwEstrutura: modal.many ? fileStructures[key] : structure,
            document: (modal?.many ? filenames[key] : document), 
            file: file?.id
        };

        if (editMode && modal?.data?.item?.id) {
            return await Update(payload, modal.data.item.id);
        } else {
            return await Create(payload);
        }
    }


    const init = async () => {
        const result = await Read()
        setReportOptions(!(result && !exposeStrapiError(result)) ? [] : result?.map(mit => ({ ...mit, title: mit.veiculo })))
        // console.log('FUNDS', result)
        const results = await ReadS()
        setStructureOptions(!(results && !exposeStrapiError(results)) ? [] : results?.map(mit => ({ ...mit, title: mit.name })))
        // console.log('STRUCTURES', results)
    }

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (modal?.data) {
            setEditMode(true);
            setSelectedType(modal.data.selectedType);
            setFund(modal.data.fund);
            setDocument(modal.data.document);
            setFile(modal.data.file);
        } else {
            setEditMode(false);
        }
    }, [modal]);

    return (
        <>
            <ModalWrapper medium>
                <ModalHeader success={success}>
                    Upload de documentos
                    <ModalHeaderClose success={success} onClick={close}>
                        x
                    </ModalHeaderClose>
                </ModalHeader>
                {
                    success ?
                        <ModalBody>
                            <ContentTableActionIconFileSuccess />
                            <FormTitle>
                                Upload de documento concluído
                            </FormTitle>
                            <FormContentActions>
                                <Button primary onClick={close}> Ok </Button>
                            </FormContentActions>
                        </ModalBody>
                        :
                        uploading ?
                            <ModalBody>
                                <LoadContent>
                                    <Load />
                                </LoadContent>
                                <FormTitle>
                                    Enviando documento
                                </FormTitle>
                                <FormText>
                                    Aguarde estamos salvando seu documento :)
                                </FormText>
                            </ModalBody>
                            :
                            <ModalBody>
                                <FormSpacer />
                                <FormSpacer />
                                <Select label={""} placeholder={"Tipo"} options={reportType} value={selectedType} onChange={setSelectedType} />
                                <FormSpacer />
                                {!modal?.many && selectedType === 1 && (
                                    <>
                                        <Select label={""} placeholder={"Estrutura"} options={structureOptions} value={structure} onChange={setStructure} />
                                        <FormSpacer />
                                    </>
                                )}
                                {!modal?.many && selectedType === 2 && (
                                    <>
                                        <Select label={""} placeholder={"Fundo"} options={reportOptions} value={fund} onChange={setFund} />
                                        <FormSpacer />
                                    </>
                                )}
                                <FormSpacer />
                                {!modal?.many && <Input placeholder={"Titulo"} value={document} onChange={e => setDocument(e.target.value)} />}

                                {
                                    file?.length ? (
                                        file.map((m, key) => (
                                            <ContentUpload key={key}>
                                                {
                                                    (selectedType === 1 || selectedType === 2) && modal?.many ? (
                                                        <>
                                                            <Input fullWidth style={{
                                                                width: '50%'
                                                            }}
                                                                placeholder='Nome do arquivo'
                                                                value={filenames[key]}
                                                                onChange={e => {
                                                                    const newFilenames = [...filenames];
                                                                    newFilenames[key] = e.target.value;
                                                                    setFilenames(newFilenames);
                                                                }}
                                                            />
                                                            {
                                                                selectedType === 1 ? (
                                                                    <ModalBodyInput>
                                                                        <Select label={""} placeholder={"Estrutura"} options={structureOptions} value={fileStructures[key]}
                                                                            onChange={e => {
                                                                                const newStructures = [...fileStructures];
                                                                                newStructures[key] = e;
                                                                                setFileStructures(newStructures);
                                                                            }} />
                                                                    </ModalBodyInput>
                                                                ) : (
                                                                    <ModalBodyInput>
                                                                        <Select label={""} placeholder={"Fundo"} options={reportOptions} value={filefunds[key]} onChange={e => setFilefunds([...filefunds?.map((i, k) => k === key ? e : i)])} />
                                                                    </ModalBodyInput>
                                                                )
                                                            }
                                                        </>
                                                    ) : (
                                                        <ContentUploadContent>
                                                            <ContentUploadText>{getFilename(m?.[0])}</ContentUploadText>
                                                        </ContentUploadContent>
                                                    )
                                                }
                                                <ContentUploadIconStatus uploaded />
                                            </ContentUpload>
                                        ))
                                    ) : (
                                        <UploadFile onChange={action} onUploadStart={() => setUploading(true)} many={modal?.many}>
                                            <ContentUpload>
                                                {!!uploaded ? <ContentUploadIcon /> : null}
                                                <ContentUploadText>
                                                    {uploaded ? uploaded.filename : 'Fazer upload de arquivo'}
                                                </ContentUploadText>
                                                <ContentUploadIconStatus uploaded={uploaded} />
                                            </ContentUpload>
                                        </UploadFile>
                                    )
                                }

                                <FormActions>
                                    <Button secondary small onClick={close}>Cancelar</Button>
                                    <Button loading={loading} small primary onClick={complete}>Fazer Upload</Button>
                                </FormActions>
                            </ModalBody>
                }
            </ModalWrapper>
        </>
    )
}